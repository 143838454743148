import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Container from '../../components/container'
import CloudIndexNav from '../../components/cloudIndexNav/cloudIndexNav'
import CloudIndexTable from '../../components/cloudIndexTable/cloudIndexTable'
// import CloudIndexResources from '../../components/cloudIndexResources/cloudIndexResources'

import Akamai from '../../img/logos/akamai'
import Atlassian from '../../img/logos/atlassian'
import C3ai from '../../img/logos/c3ai'
import Cloudflare from '../../img/logos/cloudflare'
import Confluent from '../../img/logos/confluent'
import CrowdStrike from '../../img/logos/crowdstrike'
import DataDog from '../../img/logos/datadog'
import DigitalOcean from '../../img/logos/digitalocean'
import DynaTrace from '../../img/logos/dynatrace'
import Elastic from '../../img/logos/elastic'
import GitLab from '../../img/logos/gitlab'
import Hashicorp from '../../img/logos/hashicorp'
import Jamf from '../../img/logos/jamf'
import JFrog from '../../img/logos/jfrog'
import MongoDB from '../../img/logos/mongodb'
import NewRelic from '../../img/logos/newrelic'
import Okta from '../../img/logos/okta'
import PagerDuty from '../../img/logos/pagerduty'
import PaloAlto from '../../img/logos/paloalto'
import SentinelOne from '../../img/logos/sentinelone'
import SnowFlake from '../../img/logos/snowflake'
import Splunk from '../../img/logos/splunk'
import Tenable from '../../img/logos/tenable'
import Twilio from '../../img/logos/twilio'
import UIPath from '../../img/logos/uipath'
import Zscaler from '../../img/logos/zscaler'

import * as global from '../../components/globals.module.css'
import * as styles from './infra.module.css'

export const query = graphql`
    query CloudConstituentsQuery {
        page: sanityPage(title: { eq: "Infrared" }) {
            title
            _rawBody
            metaTitle
            metaDescription
            metaKeywords
            metaImage {
                asset {
                    _id
                    url
                }
            }
        }
        constituents: allGoogleIndexConstituentsSheet {
            nodes {
                company
                logoRef: logo
                ticker
                price
                marketCap
                ceo
                subcategory
                url
                portfolio
            }
        }
    }
`

const CloudConstituentsPage = props => {
    const { data, errors } = props

    const headers = [
        {
            label: '',
            data: 'logo',
            format: 'component'
        },
        {
            label: 'Ticker',
            data: 'ticker'
        },
        {
            label: 'Price',
            data: 'price',
            format: 'currency'
        },
        {
            label: 'Market Cap',
            data: 'marketCap',
            orderby: true,
            format: 'currency'
        },
        {
            label: 'CEO',
            data: 'ceo'
        },
        {
            label: 'Subcategory',
            data: 'subcategory'
        }
    ]

    const logos = {
        akamai: Akamai,
        atlassian: Atlassian,
        c3ai: C3ai,
        cloudflare: Cloudflare,
        confluent: Confluent,
        crowdstrike: CrowdStrike,
        datadog: DataDog,
        digitalocean: DigitalOcean,
        dynatrace: DynaTrace,
        elastic: Elastic,
        gitlab: GitLab,
        hashicorp: Hashicorp,
        jamf: Jamf,
        jfrog: JFrog,
        mongodb: MongoDB,
        newrelic: NewRelic,
        okta: Okta,
        pagerduty: PagerDuty,
        paloalto: PaloAlto,
        sentinelone: SentinelOne,
        snowflake: SnowFlake,
        splunk: Splunk,
        tenable: Tenable,
        twilio: Twilio,
        uipath: UIPath,
        zscaler: Zscaler
    }

    const constituents = useMemo(() => {
        return data.constituents.nodes.map(c => {
            const _c = { ...c }
            _c.logo = logos[c.logoRef] || null

            return _c
        })
    }, [data])

    if (errors) {
        return (
          <Layout>
              <GraphQLErrorList errors={errors} />
          </Layout>
        )
    }

    return (
      <Layout page='cloudConstituents' hideContactBar version='1'>
          <SEO title='Index Constituents' description={data?.page?.metaDescription} keywords={data?.page?.metaKeywords ? data?.page?.metaKeywords.split(',') : ''} imageFB={data?.page?.metaImage.asset.url} />

          <CloudIndexNav current='constituents' />

          <div className={global.offwhite} style={{ paddingTop: '60px' }}>
              <Container>
                  <h1 className={global.highlight}>The Nasdaq Redpoint Cloud Infrastructure Software Index&trade; Constituents<span>(NQRPCI)</span></h1>

                  <CloudIndexTable headers={headers} data={constituents} />

                  <span className={styles.footnote}>
            Companies indicated with an asterisk are Redpoint portfolio companies.
          </span>
              </Container>
          </div>

          {/* <CloudIndexResources withWave={true} /> */}

      </Layout>
    )
}

export default CloudConstituentsPage
